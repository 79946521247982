 
  .widget-container {
    width: 375px;
    min-height: 300px;
    border: solid;
    position: relative;
  }
  
  /* Styles/Variables for ToggleableNav */
  .nav-toggle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    margin: 25px;
    top: 0;
    right: 0;
  }
  
  .nav-toggle__button {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.6s;
    z-index: 9999;
  }
  .nav-toggle__button:hover {
    box-shadow: 0 0 0 18px rgba(0, 0, 0, 0.1), inset 0 0 0 35px rgba(0, 0, 0, 0.1);
  }
  .nav-toggle__button:before, .nav-toggle__button:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #f2a900;
    border-radius: 5px;
    transition: 0.5s;
  }
  .nav-toggle__button:before {
    transform: translateY(-50%) rotate(45deg) scale(0);
  }
  .nav-toggle__button:after {
    transform: translateY(-50%) rotate(-45deg) scale(0);
  }
  
  .nav__items {
    pointer-events: none;
    transition: 0.5s;
  }
  
  .nav__item {
    position: relative;
    display: inline-block;
    float: right;
    clear: both;
    color: transparent;
    font-size: 14px;
    letter-spacing: -5.5px;
    text-transform: uppercase;
    text-decoration: none;
    height: 8px;
    line-height: 8px;
    white-space: nowrap;
    transform: scaleY(0.2);
    transition: 0.5s, opacity 0.1s;
  }
  button.nav__item {
    background: no-repeat;
    border: none;
    outline: none;
  }
  .nav__item:nth-child(1) {
    transition-delay: 0s;
    letter-spacing: -1px;
  }
  .nav__item:nth-child(1):before {
    transition-delay: 0s;
  }
  .nav__item:nth-child(2) {
    transition-delay: 0.1s;
    display: none;
  }
  .nav__item:nth-child(2):before {
    transition-delay: 0.1s;
  }
  .nav__item:nth-child(3) {
    transition-delay: 0.2s;
  }
  .nav__item:nth-child(3):before {
    transition-delay: 0.2s;
  }
  .nav__item:nth-child(4) {
    transition-delay: 0.3s;
    letter-spacing: -2px;
  }
  .nav__item:nth-child(4):before {
    transition-delay: 0.3s;
  }
  .nav__item:nth-child(5) {
    letter-spacing: -2px;
  }
  .nav__item:nth-child(6) {
    display: none;
  }
  .nav__item.login__item {
    letter-spacing: -5px;
  }
  .nav__item:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: #f2a900;
    transform: translateY(-50%) scaleY(5);
    transition: 0.5s;
  }
  
  .nav-toggle--open > .back-drop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9998;
  }
  .nav-toggle--open > .nav-toggle__button {
    box-shadow: 0 0 0 325px rgba(131, 94, 94, 0.1), inset 0 0 0 35px rgba(0, 0, 0, 0.1);
  }
  .nav-toggle--open > .nav-toggle__button:hover {
    box-shadow: 0 0 0 325px rgba(0, 0, 0, 0.8), inset 0 0 0 35px rgba(0, 0, 0, 0.8), 0 0 0 8px rgba(0, 0, 0, 0), inset 0 0 0 35px rgba(0, 0, 0, 0.1);
  }
  .nav-toggle--open > .nav-toggle__button:before {
    transform: translateY(-50%) rotate(45deg) scale(1.1);
  }
  .nav-toggle--open > .nav-toggle__button:after {
    transform: translateY(-50%) rotate(-45deg) scale(1.1);
  }
  .nav-toggle--open > .nav__items {
    pointer-events: auto;
    transform: translate(-62.5px, 135px);
    z-index: 9999;
    margin-top: 70px;
  }
  .nav-toggle--open > .nav__items .nav__item {
    color: #f2a900;
    letter-spacing: 0;
    font-size: 19px;
    height: 30px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    opacity: 1;
    transform: scaleY(1);
    transition: 0.5s, opacity 0.1s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(1) {
    transition-delay: 0.3s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(1):before {
    transition-delay: 0.3s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(2):before {
    transition-delay: 0.2s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(3) {
    transition-delay: 0.1s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(3):before {
    transition-delay: 0.1s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(4) {
    transition-delay: 0s;
  }
  .nav-toggle--open > .nav__items .nav__item:nth-child(4):before {
    transition-delay: 0s;
  }
  .nav-toggle--open > .nav__items .nav__item:before {
    opacity: 0;
  }