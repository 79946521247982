.nav-link {
  color: #3C4A5B;
  text-decoration: none;
}

.selected-link {
  color: #3C4A5B;
  font-weight: bolder;
  text-decoration: none;
}

.nav-link-active {
  color: #3C4A5B;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #f2aa00;
  border-radius: 24px;
  padding: 2px 18px 4px;
}
