.swal-text {
  font-family: "Bahnschift Light";
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin: 8px 0;
}

.footerLink {
  text-decoration: none;
  color: #ffffff;
}

.footerLink:hover {
  color: #f2a900;
}

.faIcon {
  color: #f2a900;
}

.iconPointer {
  color: #f2a900;
  font-size: 40px;
  margin: 10px;
  margin-left: 0px;
}

.iconPointerGray {
  color: #757575;
  font-size: 64px;
  margin: 6px 6px 6px 0;
}

.iconProfile {
  font-size: 28px;
  margin-right: 6px;
  width: 48px !important;
}

.iconTracking {
  font-size: 28px;
  margin-right: 8px;
  width: 38px !important;
}

.unverified {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.verified {
  color: rgba(243, 163, 6, 1);
}

.iconCatcher {
  font-size: 38px;
  margin: 0;
  width: 48px !important;
  color: #ffffff;
}

.iconWhite {
  color: #ffc85c;
}

.iconPointerAlert {
  color: #ad7b03;
  font-size: 24px;
  margin-right: 5px;
}

.iconCheck {
  color: #f2a900;
  font-size: 26px;
  margin-top: 10px;
}
.iconCard {
  color: #f2a900;
  font-size: 40px;
  line-height: 40px;
  border: 2px solid #f2a900;
  border-radius: 100%;
  padding: 20px 18px;
}

.iconCard.middle {
  padding: 20px 15px;
}

.boxCenter {
  text-align: center;
  justify-content: center !important;
}

.listWallets {
  justify-content: space-around !important;
  align-items: flex-start !important;
}

.listWallets li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listWallets li div {
  display: flex;
  align-items: center;
}

.buttonCta {
  background-color: #f2a900;
  color: #ffffff;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 22px;
  margin: 24px 0;
  flex: 0 1 30%;
}
@media (max-width: 640px) {
  .buttonCta {
    font-size: 14px;
    padding: 10px 15px;
  }
}
@media (max-width: 320px) {
  .buttonCta {
    font-size: 12px;
  }
  .listWallets li div {
    display: block;
  }
}

.buttonCtaBuy {
  background-color: #f2a900 !important;
  color: #ffffff;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 22px;
  margin: 12px 0;
  flex: 0 1 20%;
  text-align: center;
}

@media (max-width: 768px) {
  .buttonCtaBuy {
    flex: 0 1 30%;
  }
}

@media (max-width: 640px) {
  .buttonCtaBuy {
    flex: 0 1 60%;
    padding: 12px 0;
    border: none;
    font-size: 14px;
  }
}

.bitcoinImg {
  padding: 0;
  max-width: 120px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.paBottom {
  padding-bottom: 0;
}

.smallImg {
  width: 100%;
  max-width: 160px;
}

.mediumImg {
  width: 100%;
  max-width: 360px;
}

.active-tab {
  color: rgba(255, 200, 92, 1) !important;
  border-radius: 60px !important;
  font-weight: 600 !important;
  border: none !important;
  background: rgba(255, 200, 92, 0.4) !important;
}

.editLink {
  color: #f2a900;
  font-weight: 600;
  text-decoration: none;
}

.editLink:hover {
  text-decoration: underline;
}

.currencyImg {
  width: 100%;
  max-width: 64px;
  margin-right: 12px;
}
@media screen and (max-width: 640px) {
  .currencyImg {
    max-width: 34px;
  }
}

@media screen and (max-width: 640px) {
  .currencyImg {
    max-width: 34px;
  }

  .accordion-section.short {
    margin-right: 0;
    margin-left: 0;
  }
}

/*======= ACCORDION =======*/
.accordion-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.accordion-section.short {
  margin-right: 10%;
  margin-left: 10%;
}

.accordion-title {
  background-color: transparent;
  color: #495057;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: background-color 0.6s ease;
  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1);
  padding: 2px 20px;
  margin-bottom: 14px;
}

.accordion-title:hover,
.accordion-title:active {
  background-color: rgba(114, 125, 138, 0.05);
}

.accordion-title-content {
  font-weight: 500;
  font-size: 16px;
  color: #f58634;
  text-align: left;
}

.accordion-title-content:hover,
.accordion-title-content:active {
  color: #0a1538;
}

.accordion-icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  color: #495057;
  font-weight: 600;
}

.rotate {
  transform: rotate(90deg);
}

.accordion-content {
  font-size: 14px;
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
  font-weight: 300;
}

.radio label {
  display: flex;
  align-items: center;
}

.editMoreBtn {
  color: #f2a900;
  font-size: 18px;
}

.editMoreBtnInactive {
  cursor: default;
}

.StatsBox {
  position: absolute;
  right: 120px;
  top: 138px;
  transition: all ease-in-out 2s;
}

.section-top {
  border-bottom: 4px solid #f2aa00;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}

.section-bottom {
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}

.outer-box {
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  border: 4px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
}

.confirma-compra {
  background: #f2a900 !important;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 12px 18px;
  border-radius: 24px;
  justify-content: center !important;
  flex: 0 1 100%;
}

.voucher-image {
  flex: 0 1 100%;
  max-width: 440px;
  margin-top: 24px;
  border: 1px solid #f2aa00;
  border-radius: 8px;
  order: 0 !important;
}

.detalle-compra {
  background: #f2aa00;
  color: #fff;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.img-center {
  margin: 16px auto;
  height: 240px;
}

.text-center {
  flex: 0 1 100%;
  order: 1;
  text-align: center;
}

.form-venta {
  flex: 0 1 40%;
}

.section-profile {
  align-items: center;
}

.text-profile {
  flex: 0 1 100%;
  text-align: center;
}

.alert-heading {
  flex: 0 1 90%;
  flex-wrap: nowrap;
}

.alert-text {
  font-size: 16px;
}

.socialLink {
  color: #ffffff;
  margin: 0 4px;
  font-size: 24px;
  line-height: 24px;
}
.socialLink.facebook {
  color: #ffffff;
}
.socialLink.instagram {
  color: #ffffff;
}

.hxNodC div:after {
  background: #f2a900;
}

.contact-item {
  font-size: 14px;
  margin-bottom: 10px;
}

.data-list {
  justify-content: center !important;
}

.data-list-item {
  justify-content: center !important;
  flex: 0 1 80% !important;
}

.data-list-100 {
  flex: 0 1 100% !important;
}

.data-list-70 {
  flex: 0 1 70% !important;
}

.data-list-50 {
  flex: 0 1 50% !important;
}

.data-list-30 {
  flex: 0 1 30% !important;
}

.form-item-46 {
  display: flex;
  flex-direction: column;
  flex: 0 1 46%;
  margin: 54px 0 36px;
}

.no-padding {
  padding: 0;
}

/*======= DASHBOARD =======*/

.dashboard-link {
  display: block;
  width: 100%;
  padding: 12px 0;
  border: none;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  opacity: 0.5;
  font-family: Poppins, sant-serif;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}

.dashboard-active {
  opacity: 1;
  color: #f2a900;
  font-weight: 600;
  border: 2px solid #f2a900;
  background: transparent;
}
.btn-dashboard {
  width: 100%;
}
.btn-currency {
  background: rgba(255, 255, 255, 0.5);
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.activeBtn {
  background: rgb(243, 163, 6);
}

/*======= MEDIA QUERIES =======*/

@media (max-width: 768px) {
  .buttonCta {
    font-size: 10px;
  }

  .StatsBox {
    top: 80px;
  }
  .Calculator {
    flex: 1 1 100%;
  }
  .editMoreBtn,
  .editMoreBtnInactive {
    flex: 0 1 100%;
    justify-content: flex-start;
  }
  .section-bottom,
  .detalle-compra li {
    padding: 0 12px;
    box-sizing: border-box;
  }
  .hide-mobile {
    display: none !important;
  }
  .show-desktop {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .show-mobile {
    display: none;
  }
  .flex100 {
    flex: 0 1 100%;
  }
  .alert-text {
    flex: 0 1 90%;
  }
}

@media (max-width: 640px) {
  .form-item-46 {
    flex: 0 1 100%;
  }

  .data-list-item {
    flex: 0 1 100%;
  }

  .text-profile {
    flex: 0 1 100%;
  }
  .file-form {
    width: 100%;
    max-width: 300px;
    margin-left: 10px;
  }
  .form-venta {
    flex: 0 1 100%;
  }
  /* .confirma-compra {
    margin: 24px auto;
    max-width: 300px;
  } */
  .buttonCta {
    font-size: 14px;
    padding: 10px 15px;
  }
  .logo-interbank {
    width: auto;
    height: auto;
  }
  .listWallets {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .wallet-key {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .Heading1 {
    font-size: 36px;
  }
  .TaglineHome {
    font-size: 16px;
  }
  .StatsBox {
    top: 80px;
  }
  .advertencia {
    width: 480px;
  }
  .loaderWrapper {
    display: flex !important;
    justify-content: center;
    padding: 120px 0;
  }
}

@media (max-width: 320px) {
  .wallet-key {
    font-size: 12px;
  }
  .text-profile {
    flex: 0 1 100%;
  }
  .iconProfile {
    width: 24px !important;
  }
  .editMoreBtn,
  .editMoreBtnInactive {
    font-size: 14px;
  }
  .file-form {
    max-width: 236px;
  }
  .confirma-compra {
    max-width: 256px;
  }
  .img-center {
    margin: 0;
  }
  .text-center {
    padding: 0;
    text-align: left;
    font-size: 12px;
  }
  .buttonCta {
    font-size: 12px;
  }
}

/*--------FAQ---------*/
.btnFaq {
  cursor: pointer;
  padding: 9px 36px;
  border: none;
  color: #3c4a5b;
  outline: none;
  margin: 10px;
  border: none;
  border-radius: 24px;
  opacity: 0.5;
  font-weight: 600;
  font-size: 14px;
}

.activeBtn2 {
  background: rgba(255, 200, 92, 0.2);
  color: #ffc85c;
  opacity: 1;
}

.FaqDivClient {
  display: none;
}

.FaqDivSocio {
  display: none;
}

.activeDiv {
  display: block;
}
/*.btnPartner button:hover {
    color: #fff;
    background:#f2a900;
  }*/

.contentTable {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

table.unstyledTable {
  text-align: center;
  margin-top: 40px;
  border-top: 2px solid #e8eaec;
}
table.unstyledTable td,
table.unstyledTable th {
  border-bottom: 1px solid #e8eaec;
  padding: 15px 15px;
  font-weight: 300;
  font-size: 15px;
}
table.unstyledTable thead {
  background: #f5f6f6;
}
table.unstyledTable thead th {
  font-size: 13px;
  font-weight: 600;
  color: #3c4a5b;
  text-align: center;
}
table.unstyledTable tfoot {
  font-weight: bold;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    font-size: 10px;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 10px;
    left: 0px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: 500;
  }

  table.unstyledTable td:nth-of-type(1):before {
    content: "";
  }
  table.unstyledTable td:nth-of-type(2):before {
    content: "Venta";
  }
  table.unstyledTable td:nth-of-type(3):before {
    content: "Compra";
  }
}

/** 
  
  Dropdown menu top (27-04-2020)
  
  **/

.dropdown-arrow {
  height: 0;
  width: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
  position: relative;
  top: -18px;
  left: 85px;
}

.menu-dropdown:hover .dropdown-arrow {
  top: -18px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #333;
  border-top: 10px solid transparent;
}

.dropdown-menu {
  list-style-type: none;
  display: none;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1);
  border-radius: 8px;
  margin-top: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  z-index: 1;
}

.menu-dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  margin-top: 0;
}

.dropdown-menu ul {
  padding-left: 0px;
  min-width: 450px;
}
.dropdown-menu ul li {
  list-style-type: none;
  display: block;
  height: 50px;
  font-size: 14px;
  line-height: 2.2em;
  padding: 15px 20px 25px 10px;
  color: #495057;
  border-bottom: 1px solid #d8dbde;
}

.dropdown-menu ul > a {
  text-decoration: none;
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li:hover {
  background: #f5f6f6;
}

.item-content {
  display: flex;
  align-items: center;
}

.item-content .left {
  flex-grow: 0;
  flex: 0 0 50px;
}

.item-content .right {
  flex-grow: 1;
}

.item-content .left img {
  width: 60px;
}

.item-content .right .sm-title {
  font-weight: 600;
}

.item-content .right .sm-description {
  font-weight: 400;
}

/* Box Price */

.box-prices {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-prices {
  /* box-shadow: 0 6px 9px 6px rgba(0,0,0,0.06);
  background: white; */
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 38px;
  padding-bottom: 20px;
  border-radius: 8px;
  color: #000;
  /* margin-bottom:90px; */
  display: flex;
  min-width: 450px;
}

.content-prices .column {
  display: flex;
  flex-direction: column;
  flex: 0 0 250px;
}

.content-prices .column.center {
  text-align: center;
}

.content-prices .column .title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
}

.content-prices .column .subtitle {
  font-weight: 600;
  font-size: 17px;
  margin-top: 4px;
  text-align: center;
  line-height: 1;
}

.content-prices .column .subtitle.blue {
  /* color: #1A4BEA; */
  color: #000;
  font-weight: 700;
}

/* Fix responsive */

@media screen and (max-width: 640px) {
  a.NavLogo {
    margin: 0 auto;
  }

  .content-prices {
    min-width: 120px;
  }

  .content-prices .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-shrink: 1;
  }

  .contentTable {
    width: auto;
  }
}

@media screen and (max-width: 640px) {
  #right {
    margin: 0 auto;
    padding-right: 0;
    padding: 30px;
  }

  .banner-new {
    flex-direction: column;
    padding: 10px 3px;
  }
}

.banner-new {
  min-height: 30px;
  height: auto;
  z-index: 999;
  padding: 10px 0px;
  width: 100%;
  background-color: #1a4bea;
  display: flex;
  position: relative;
}

.banner-text {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-align: center;
  width: 100%;
}
.banner-button {
  font-size: 15px;
  font-weight: 700;
  background: #fff;
  border-radius: 5px;
  padding: 8px 10px;
  text-decoration: none;
  color: #1a4bea;
}

.banner-button:hover {
  opacity: 0.9;
}

#left,
#right {
  position: relative;
  box-sizing: border-box;
  padding-right: 15%;
  white-space: nowrap;
}

@media screen and (max-width: 640px) {
  .content-prices .column .subtitle {
    font-size: 15px;
  }
  .content-prices .column .title {
    font-size: 18px;
  }
}
