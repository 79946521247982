.container {
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
  position: fixed;
  max-width: 330px;
  max-height: 300px;
  width: 330px;
  bottom: -100%;
  /* bottom: 30px; */
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
  opacity: 0;
  transition: all 0.5s ease; 
  z-index: 1;
  color: #000;
}
.close {
  width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 2px;
}
.title {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.description {
  margin: 0;
  text-align: center;
}

.logo {
  height: 20px;
  width: 20px;
  margin: 10px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.show {
  bottom: 30px;
  opacity: 1;
}

@media screen and (max-width: 819px) {
  .container {
    bottom: -100%;
  }
}